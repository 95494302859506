@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Atkinson+Hyperlegible&family=Chakra+Petch&family=Comfortaa&family=Dosis&family=Gloria+Hallelujah&family=Jua&family=Kaushan+Script&family=Lato&family=Lexend&family=Lobster&family=Monoton&family=Montserrat&family=Nanum+Gothic&family=Orbitron&family=Oswald&family=Palette+Mosaic&family=Patrick+Hand&family=Permanent+Marker&family=Poiret+One&family=Poppins&family=Press+Start+2P&family=Rajdhani&family=Roboto&family=Rubik&family=Shadows+Into+Light&family=Sigmar+One&family=Style+Script&family=Teko&family=Tourney&family=Ubuntu&family=Yomogi&display=swap);
body {
  margin: 0;
  font-family: "Lexend", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tooltip {
  border-radius: 0.25rem;
  background: #26313c;
  color: #fff;
  padding: 1rem;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
}

/* hiding first and last lines in cartesian grid */
.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}

.recharts-responsive-container {
  background-color: #ffffff;
  padding-top: 12px;
  height: 432px !important;
}

.component-selected {
  position: relative;
}

.component-selected::after {
  content: " ";
  border: 3px solid #00e1ff;
  width: 100% !important;
  height: 100% !important;
  border-radius: 3px;
  /* padding: 10px; */
  padding-right: 0;
  padding-left: 10px;
  position: absolute !important;
  left: 0;
  top: 0;
  pointer-events: none;
  display: block;
  overflow: auto;
}

