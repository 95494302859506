.component-selected {
  position: relative;
}

.component-selected::after {
  content: " ";
  border: 3px solid #00e1ff;
  width: 100% !important;
  height: 100% !important;
  border-radius: 3px;
  /* padding: 10px; */
  padding-right: 0;
  padding-left: 10px;
  position: absolute !important;
  left: 0;
  top: 0;
  pointer-events: none;
  display: block;
  overflow: auto;
}
