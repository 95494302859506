@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Atkinson+Hyperlegible&family=Chakra+Petch&family=Comfortaa&family=Dosis&family=Gloria+Hallelujah&family=Jua&family=Kaushan+Script&family=Lato&family=Lexend&family=Lobster&family=Monoton&family=Montserrat&family=Nanum+Gothic&family=Orbitron&family=Oswald&family=Palette+Mosaic&family=Patrick+Hand&family=Permanent+Marker&family=Poiret+One&family=Poppins&family=Press+Start+2P&family=Rajdhani&family=Roboto&family=Rubik&family=Shadows+Into+Light&family=Sigmar+One&family=Style+Script&family=Teko&family=Tourney&family=Ubuntu&family=Yomogi&display=swap");

body {
  margin: 0;
  font-family: "Lexend", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
